<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Apply On Duty Request" slot="title" link="/helpContent/applyOD" />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-2">
            <FormCheckbox v-model="form.fullDay">Is OD full day</FormCheckbox>
          </div>
      </FormRow>
    <FormRow>
 
      <div class="col-sm-2">
          <inputDate
            label="Start Date"
            v-model="form.startDate"
            rules="required"
            @onChange="setEndDate"
          />
        </div>
        <div class="col-sm-2" v-if="!form.fullDay">
          <InputTime
            label="Start Time"
            v-model="form.startTime"
            rules="required"
          />
        </div>
   
    </FormRow>
      <FormRow>
        <div class="col-sm-2">
          <inputDate label="End Date" v-model="form.endDate" rules="required" />
        </div>
        <div class="col-sm-2">
          <InputTime label="End Time" v-model="form.endTime" rules="required" v-if="!form.fullDay"/>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-5">
          <inputTextArea label="Reason" v-model="form.reason" rules="required" />
        </div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
<!-- 
    <FormRow>
      <div class="col-md-12">
        <h3>Leave Matrix</h3>
        <br />
        <DataTable
          :actions="true"
          :data="leaveMatrixData"
          :columns="tableConfig"
          :is-loading="isLoading"
        >
        </DataTable>
      </div>
    </FormRow> -->

    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (infoMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { leaveMatrix, odRequest,odRequestList } from "../api";
import InputDate from "Components/form/InputDate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
import tableConfig from "./table";
import DataTable from "Components/DataTable";
import FormCheckbox from 'Components/form/FormCheckbox';

export default {
  name: "add",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    InputDate,
    FormWrapper,
    PageTitle,
    // DataTable,
    ValidationObserver,
    FormCheckbox,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      form: {
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        reason: "",
        id:null,
        fullDay:false
      },
      tableConfig,
      leaveMatrixData: [],
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: { },

  created() {
    this.getLeaveRecord();
    if (this.$route.params.id) {
    
      this.form.id=atob(this.$route.params.id);
     this.getData();
    }
  },
  methods: {
    setEndDate(val){
      if (!this.form.endDate) {
      this.form.endDate=val;
      }
    },
    getData(){
      const filters = {};
      filters.id=this.form.id
      const data = {
        filterjson: {
          filter: [filters],
        },
      };
      odRequestList(data).then(res=>{
        this.form.startDate=res.data[0].OD_startdate;
        this.form.endDate=res.data[0].OD_enddate;
        this.form.startTime=res.data[0].StartTime;
        this.form.endTime=res.data[0].EndTime;
        this.form.reason=res.data[0].Reason;
        this.form.id=res.data[0].ID;
        this.form.fullDay=res.data[0].fullDay==1?true:false;
      })
    },
    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
      const data = {
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        startTime: this.form.startTime,
        endTime: this.form.endTime,
        reason: this.form.reason,
        id:this.form.id,
        fullDay:this.form.fullDay?1:0
      };

      odRequest(data).then((res) => {
        if (res.data[0].status == 1) {
          this.form = {};
          this.showMessage.isVisible = true;
          this.showMessage.message = res.data[0].message;
        } else {
          this.infoMessage.isVisible = true;
          this.infoMessage.message = res.data[0].message;
        }
      });
    }
          });
    },
    getLeaveRecord() {
      let data = {
        staff_id: this.userData.user_id,
      };
      leaveMatrix(data).then((res) => {
        this.leaveMatrixData = res.data;
      });
    },
  },
};
</script>
<style lang="scss">
    textarea{
        height: 180px !important;
    }
</style>