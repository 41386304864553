export default [
    {
        title: 'Months',
        key: 'Title',
        minWidth: 80,
    },

	{
        title: 'CL',
        key: 'CL',
        minWidth: 80,
    },

    
    {
        title: 'EL',
        key: 'EL',
        minWidth: 80,
    },
    {
        title: 'SL',
        key: 'SL',
        minWidth: 80,
    },
    {
        title: 'RC',
        key: 'RC',
        minWidth: 80,
    },
    {
        title: 'ALWP',
        key: 'ALWP',
        minWidth: 80,
    },
    {
        title: 'NAWP',
        key: 'NAWP',
        minWidth: 80,
    },
    {
        title: 'OD',
        key: 'OD',
        minWidth: 80,
    },
    {
        title: 'MPR',
        key: 'MPR',
        minWidth: 80,
    },
    {
        title: 'MLR',
        key: 'MLR',
        minWidth: 80,
    },
]
